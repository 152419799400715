import { debounce } from 'lodash';
import 'bootstrap';
import '../less/theme.less';

function setEqualHeight($el) {

	var max = 0;

	$el
		.css('height', 'auto')
		.each(function(index, item) {
			if($(item).height() > max) {
					max = $(item).height()
			}
		})
		.height(max);
}

$(function() {

	$('a[href*="#"]:not([href="#"])').click(function(ev) {
    if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'')
			&& location.hostname === this.hostname
		) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if(target.length< 1) {
      	return
			}
			$('html,body')
				.stop()
				.animate({
					scrollTop: target.first().offset().top
				}, 1000);
    }
  });

	$('body').scrollspy({
		target : '.navbar-fixed-top'
	});

	$('.navbar-collapse ul li a').click(function() {
		$('.navbar-toggle:visible').click();
	});

	var $portfolioItems = $('.client'),
		$navbar = $('.navbar-default'),
		$form = $('#contact form');

	setEqualHeight($portfolioItems);

	function checkScrollPos() {
		var offsetY = window.pageYOffset || document.documentElement.scrollTop;
		if(offsetY > 200) {
			$navbar.addClass('navbar-shrink');
		} else {
			$navbar.removeClass('navbar-shrink');
		}
	}

	checkScrollPos();

	$(window)
		.scroll(debounce(checkScrollPos, 100, false ))
		.resize(debounce(function() {
				setEqualHeight($portfolioItems);
		}, 100, false ));
});


